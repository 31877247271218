import React, { useState, useEffect } from 'react';
import Map from '../components/Map';
import SearchBar from '../components/Searchbar';
import AddCourt from '../components/AddCourt';
import CreateGameForm from '../components/CreateGameForm';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import '../components/Locs.css';


const MapPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.value);
  const [showAddCourt, setShowAddCourt] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCityCoordinates, setSelectedCityCoordinates] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const access_token = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (access_token) {
        dispatch(setAuth(true));
      } else {
        dispatch(setAuth(false));
      }
    };

    checkAuth();
  }, [dispatch]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('content/v1/cities/');
        setCities(response.data);
        alert('Если площадки сразу не появились на карте, повторно обновите страницу.');
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const handleCityChange = async (event) => {
    const cityName = event.target.value;
    const geocodedCity = await geocodeAddress(cityName);
    if (geocodedCity) {
      setSelectedCityCoordinates({
        lat: geocodedCity.lat(),
        lng: geocodedCity.lng(),
      });
      setSelectedLocation(null);
    } else {
      console.error('Failed to geocode city:', cityName);
    }
  };

  const handleSelect = async (location) => {
    console.log('Selected location:', location);

    const geocodedLocation = await geocodeAddress(location.address);
    if (geocodedLocation) {
      setSelectedLocation({
        ...location,
        lat: geocodedLocation.lat(),
        lng: geocodedLocation.lng(),
      });
      setSelectedCityCoordinates(null);
    } else {
      console.error('Failed to geocode address:', location.address);
    }
  };

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          resolve(results[0].geometry.location);
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
          resolve(null);
        }
      });
    });
  };

  const handleToggleAddCourt = () => {
    setShowAddCourt(!showAddCourt);
  };

  return (
    <div className="container">
      <section className="infor">
        <div className="infor-item">
          <center><h3>Площадки, собранные для вас!</h3></center>
        
          <center><p>Выберите свой город, найдите наиболее удобную для вас площадку, присоединитесь к уже существующей игре, или создайте свою!</p></center>
         
          <center><p>Добавляйте площадку в своем районе:</p></center>
          <center><button className="toggle-button" onClick={handleToggleAddCourt}>
          {showAddCourt ? 'Добавить!' : 'Добавить!'}
        </button></center>
        <div className="separator"></div> {/* Add this line */}
        </div>
        
        
      </section>

      {showAddCourt && (
        <div className="modal-overlay" onClick={handleToggleAddCourt}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleToggleAddCourt}>×</button>
            <AddCourt />
          </div>
        </div>
      )}

      <header className="header">
        <select
          className="dropdown"
          value={selectedCityCoordinates ? selectedCityCoordinates.name : ''}
          onChange={handleCityChange}
        >
          <option value="">Выбрать город</option>
          {cities.map((city, index) => (
            <option key={index} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>
        <SearchBar onSelect={handleSelect} />
      </header>

      <div className="map-container">
        <Map selectedLocation={selectedLocation} selectedCityCoordinates={selectedCityCoordinates} />
      </div>

    </div>
  );
};

export default MapPage;
