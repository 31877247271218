// SearchBar.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useOnclickOutside from 'react-cool-onclickoutside';
import './Searchbar.css';

const SearchBar = ({ onSelect }) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('content/v1/locations/', {
          
        });
        console.log('Locations:', response.data); // Debug line
        setLocations(response.data);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  const ref = useOnclickOutside(() => {
    setSuggestions([]);
  });

  const handleInput = (e) => {
    const query = e.target.value;
    setValue(query);

    if (query.length > 0) {
      const filteredSuggestions = locations.filter(location =>
        location.address.toLowerCase().startsWith(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      console.log('Filtered Suggestions:', filteredSuggestions); // Debug line
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (location) => () => {
    setValue(location.address);
    setSuggestions([]);
    onSelect(location);
  };

  const renderSuggestions = () =>
    suggestions.map((suggestion) => (
      <li key={suggestion.id} onClick={handleSelect(suggestion)}>
        <strong>{suggestion.name}</strong> <small>{suggestion.address}</small>
      </li>
    ));

  return (
    <div className="search-container" ref={ref}>
      <input
        type="text"
        name="search"
        placeholder="Поиск..."
        className="search-input"
        value={value}
        onChange={handleInput}
      />
      <a href="#" className="search-btn">
        <i className="fas fa-search"></i>
      </a>
      {suggestions.length > 0 && (
        <ul className="autocomplete-suggestions">
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;