"use client";

import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Площадки", href: "/locations" },
  { name: "События", href: "/events" },
  { name: "Рейтинг", href: "/scoreboard" },
];

const Navbar = () => {
  const auth = useSelector((state) => state.auth.value);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const logout = async () => {
    const access_token = document.cookie
      .split('; ')
      .find((row) => row.startsWith('access_token='))
      ?.split('=')[1];

    const refresh_token = document.cookie
      .split('; ')
      .find((row) => row.startsWith('refresh_token='))
      ?.split('=')[1];
  
    if (!access_token || !refresh_token) {
      console.log('Tokens not found');
      dispatch(setAuth(false));
      return;
    } 

    try {
      await axios.post(
        'auth_service/v1/logout/',
        { refresh_token },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      // Clear tokens from cookies
      document.cookie =
        'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      dispatch(setAuth(false));
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const handleLinkClick = (href) => {
    setMobileMenuOpen(false);
    navigate(href);
  };

  let browser_links;
  let mobile_links;

  if (auth) {
    browser_links = (
      <div className="hidden lg:flex lg:gap-x-8 lg:flex-1 lg:justify-center">
        <Link to={'/'} onClick={logout} className="text-xs font-semibold leading-5 text-gray-900 no-underline border-b-2 border-transparent hover:border-gray-500">
          Выйти
        </Link>
        <Link to={'/profile'} className="text-xs font-semibold leading-5 text-gray-900 no-underline border-b-2 border-transparent hover:border-gray-500">
          Профиль
        </Link>
      </div>
    );

    mobile_links = (
      <div className="py-4 text-center">
        <Link
          to={'/'}
          onClick={() => {
            logout();
            handleLinkClick('/');
          }}
          className="-mx-3 block rounded-lg px-3 py-2 text-base no-underline font-semibold leading-6 text-gray-900 hover:bg-gray-50"
        >
          Выйти
        </Link>
        <Link
          to={'/profile'}
          onClick={() => handleLinkClick('/profile')}
          className="-mx-3 block rounded-lg px-3 py-2 text-base no-underline font-semibold leading-6 text-gray-900 hover:bg-gray-50"
        >
          Профиль
        </Link>
      </div>
    );

  } else {
    browser_links = (
      <div className="hidden lg:flex lg:gap-x-8 lg:flex-1 lg:justify-center">
        <Link to={'/ordinary-user-signup'} className="text-xs font-semibold leading-5 text-gray-900 no-underline border-b-2 border-transparent hover:border-gray-500">
          Зарегистрироваться
        </Link>
        <Link to={'/login'} className="text-xs font-semibold leading-5 text-gray-900 no-underline border-b-2 border-transparent hover:border-gray-500">
          Войти
        </Link>
      </div>
    );

    mobile_links = (
      <div className="py-4 text-center">
        <Link
          to={'/ordinary-user-signup'}
          onClick={() => handleLinkClick('/ordinary-user-signup')}
          className="-mx-3 block rounded-lg px-3 py-2 text-base no-underline font-semibold leading-6 text-gray-900 hover:bg-gray-50"
        >
          Зарегистрироваться
        </Link>
        <Link
          to={'/login'}
          onClick={() => handleLinkClick('/login')}
          className="-mx-3 block rounded-lg px-3 py-2 text-base no-underline font-semibold leading-6 text-gray-900 hover:bg-gray-50"
        >
          Войти
        </Link>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <header className="inset-x-0 top-0 z-50 bg-white border-b-2 border-gray-200">
        <nav
          aria-label="Global"
          className="flex items-center justify-between py-1 lg:px-6"
        >
          <div className="flex lg:flex-1 lg:justify-start">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only"></span>
              <img
                alt=""
                src="/pocket-sport-logo.png"
                className="h-28 w-auto"
              />
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-center">
            <div className="flex gap-x-8">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-xs font-semibold leading-5 text-gray-900 no-underline border-b-2 border-transparent hover:border-gray-500"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {browser_links}
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-5 w-5" />
            </button>
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50 bg-gray-900/50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-4 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src="/pocket-sport-logo.png"
                  className="h-28 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
            <div className="mt-4 flow-root">
              <div className="-my-4 divide-y divide-gray-500/10">
                <div className="space-y-2 py-4 text-center">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base no-underline font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-4">
                  {mobile_links}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </div>
  );
};

export default Navbar;
