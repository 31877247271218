import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import getCookie from '../interceptors/axios';

const CreateGameForm = ({ onClose, onCreate, locationName }) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('content/v1/sport-categories/');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const handleFormSubmit = async (values, acceptedFiles, setSubmitting) => {
    const token = getCookie('access_token');

    if (!token) {
      setError('You need to login first');
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    const eventDateTime = `${values.event_date} ${values.start_time}`;

    formData.append('name', values.name);
    formData.append('event_date', eventDateTime);
    formData.append('duration', values.duration);
    formData.append('description', values.description);
    formData.append('price', 0);
    formData.append('capacity', values.capacity);
    formData.append('category', values.category);
    formData.append('location_name', locationName);

    try {
      const response = await axios.post('content/v1/user-event/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        onCreate(values);
        onClose(); // Ensure the modal closes after successful submission
      }
      setError('');
    } catch (error) {
      if (error.response) {
        const errorResponse = error.response.data;
        let errorMessage = 'An unexpected error occurred. Please try again later.';

        if (errorResponse.message?.non_field_errors) {
          errorMessage = errorResponse.message.non_field_errors.join(' ');
        }

        setError(errorMessage);
      } else {
        setError('Network error. Please check your internet connection.');
      }
      console.error('Error creating game:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const today = new Date().toISOString().split('T')[0];

  return (
  <div
    className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75 px-4"
    onClick={onClose}
  >
    <div
      className="relative w-full max-w-lg bg-white rounded-lg shadow-lg p-6 md:p-8 lg:p-10 max-h-[90vh] overflow-y-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={onClose}
      >
        ×
      </button>

      <main className="form-add-event">
        {success && (
          <div className="alert-success text-center text-green-600 mb-4 font-semibold">
            Игра успешно создана!
          </div>
        )}
        {!success && (
          <Formik
            initialValues={{
              name: '',
              event_date: '',
              start_time: '',
              duration: '',
              description: '',
              capacity: '',
              category: '',
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
              event_date: Yup.string().required('Required'),
              start_time: Yup.string().required('Required'),
              duration: Yup.string().required('Required'),
              description: Yup.string().required('Required'),
              capacity: Yup.string().required('Required'),
              category: Yup.string().required('Required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values, acceptedFiles, setSubmitting);
            }}
          >
            <Form className="space-y-6">
              {error && (
                <div className="text-red-600 mb-4 text-center font-semibold">
                  {error}
                </div>
              )}

              <div className="form-group">
                <label htmlFor="name" className="label block font-semibold mb-1">
                  Название
                </label>
                <Field
                  name="name"
                  type="text"
                  className="input-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="event_date"
                  className="label block font-semibold mb-1"
                >
                  Дата
                </label>
                <Field
                  name="event_date"
                  type="date"
                  className="input-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  min={today}
                />
                <ErrorMessage
                  name="event_date"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="start_time"
                  className="label block font-semibold mb-1"
                >
                  Время начала
                </label>
                <Field
                  name="start_time"
                  type="time"
                  className="input-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <ErrorMessage
                  name="start_time"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="duration"
                  className="label block font-semibold mb-1"
                >
                  Продолжительность
                </label>
                <Field
                  as="select"
                  name="duration"
                  className="input-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="">Выберите продолжительность игры</option>
                  <option value="01:00:00">1 час</option>
                  <option value="01:30:00">1 час 30 минут</option>
                  <option value="02:00:00">2 часа</option>
                  <option value="02:30:00">2 часа 30 минут</option>
                  <option value="03:00:00">3 часа</option>
                </Field>
                <ErrorMessage
                  name="duration"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="description"
                  className="label block font-semibold mb-1"
                >
                  Описание
                </label>
                <Field
                  name="description"
                  as="textarea"
                  className="textarea-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="capacity"
                  className="label block font-semibold mb-1"
                >
                  Участники
                </label>
                <Field
                  as="select"
                  name="capacity"
                  className="input-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="">Выберите макс. количество участников</option>
                  {Array.from({ length: 30 }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="capacity"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="category"
                  className="label block font-semibold mb-1"
                >
                  Категория
                </label>
                <Field
                  as="select"
                  name="category"
                  className="input-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="">Выберите категорию спорта</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="flex justify-between items-center">
                <button
                  type="reset"
                  className="reset-button px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg font-semibold text-gray-700"
                >
                  Сбросить
                </button>
                <button
                  type="submit"
                  className="submit-button px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg font-semibold"
                >
                  Создать
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </main>
    </div>
  </div>
);
};

export default CreateGameForm;
