import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import getCookie from '../interceptors/axios';
import '../components/locations.css';
import { MapIcon, CalendarIcon, ChartBarIcon} from '@heroicons/react/20/solid';

const features = [
  {
    name: 'Карта местных площадок.',
    description:
      'Перейдите на вкладку "Площадки" и найдите подходящую именно вам. Или, добавьте свою площадку, которой еще нет на карте, а мы ее рассмотрем и опубликуем.',
    icon: MapIcon,
  },
  {
    name: 'Автообновляемое расписание игр.',
    description: 'Перейдите на вкладку "События" и наслаждайтесь актуальными предстоящими игрыми, имея возможность отфильтровать по городу, спортивной категории и дате.',
    icon: CalendarIcon,
  },
  {
    name: 'Рейтинг.',
    description: 'Отслеживайте свой прогресс и соревнуйтесь с другими пользователями. Самые активные участники топ рейтинга будут вознаграждаться денежными призами.',
    icon: ChartBarIcon,
  },
];

const Locations = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.value);
  const [showAddCourt, setShowAddCourt] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

   useEffect(() => {
    const checkAuth = async () => {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (accessToken) {
        dispatch(setAuth(true));
      } else {
        dispatch(setAuth(false));
      }
    };

    checkAuth();
  }, [dispatch]);

  useEffect(() => {
    const access_token = getCookie('access_token');
    const fetchLocations = async () => {
      try {
        const response = await axios.get('content/v1/locations/');
        setLocations(response.data);
        if (access_token) {
          dispatch(setAuth(true));
        } else {
          dispatch(setAuth(false));
        }
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching locations:', error);
        dispatch(setAuth(false));
      }
    };

    fetchLocations();
  }, [dispatch]);

  const handleToggleAddCourt = () => {
    setShowAddCourt(!showAddCourt);
  };

  const handleViewLocation = async (locationId) => {
    if (selectedLocation && selectedLocation.id === locationId) {
      setSelectedLocation(null);
      return;
    }
    const token = getCookie('access_token');
    try {
      const response = await axios.get(`content/v1/locations/${locationId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSelectedLocation(response.data);
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const handleCloseLocationDetails = () => {
    setSelectedLocation(null);
  };

  return (
    <div className="bg-white">
      <div className="relative">
        <img
          src="/loc_img.jpg"
          alt="Description of the image"
          className="w-full h-64 object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-50">
          <div className="text-center">
            <h3 className="text-3xl font-bold">Что такое Pocket Sport?</h3>
            <p className="mt-2 text-lg">
              Это платформа, которая позволяет вам находить и добавлять местные спортивные площадки, а также создавать на них игры и присоединяться к уже существующим.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-12 sm:py-16">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="lg:pr-8">
            <h3 className="text-xl font-semibold leading-7 text-indigo-600">Как это работает?</h3>
            <ol className="mt-4 space-y-4 list-decimal list-inside text-lg leading-8 text-gray-700">
              <li>Зарегистрируйтесь или войдите в систему.</li>
              <li>Добавьте свои местные спортивные площадки.</li>
              <li>Ищите подходящие вам игры и присоединяйтесь к ним.</li>
            </ol>
          </div>
          <div className="relative">
            <img
              src="about.jpg"
              alt="Sport Court"
              className="w-full h-64 object-cover rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">❤️ Создавай и присоединяйся к играм когда хочешь</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Теперь это легко и быстро</p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600" />
                      <dt className="inline font-semibold text-gray-900">{feature.name}</dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              alt="Map screenshot"
              src="/hero-section-map.png"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mb-10">
              <a
                href="/locations"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Найти площадки
              </a>
        </div>
    </div>
  );
};

export default Locations;
