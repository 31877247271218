import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';
import LocationCard from './LocationCard';

const containerStyle = {
  width: '100%',
  height: '65vh'
};

const defaultCenter = {
  lat: 55.7558,
  lng: 37.6173
};

const Map = ({ selectedLocation, selectedCityCoordinates }) => {
  const [locations, setLocations] = useState([]);
  const [selected, setSelected] = useState(null);
  const [map, setMap] = useState(null);

  const baseURL=process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const token = getCookie('access_token');
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}content/v1/locations/`);
        console.log(axios.get('content/v1/locations/'))
        const locationsData = response.data;
        const geocodedLocations = await geocodeAddresses(locationsData);
        setLocations(geocodedLocations);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    if (selectedLocation && map) {
      const latLng = new window.google.maps.LatLng(selectedLocation.lat, selectedLocation.lng);
      map.panTo(latLng);
      map.setZoom(15);
      setSelected(selectedLocation);
    }
  }, [selectedLocation, map]);

  useEffect(() => {
    if (selectedCityCoordinates && map) {
      const latLng = new window.google.maps.LatLng(selectedCityCoordinates.lat, selectedCityCoordinates.lng);
      map.panTo(latLng);
      map.setZoom(12);
    }
  }, [selectedCityCoordinates, map]);

  const geocodeAddresses = async (locations) => {
    const geocoder = new window.google.maps.Geocoder();
    const geocodedLocations = [];

    for (const location of locations) {
      const address = location.address;
      const geocodedLocation = await geocodeAddress(geocoder, address);
      if (geocodedLocation) {
        geocodedLocations.push({
          ...location,
          lat: geocodedLocation.lat(),
          lng: geocodedLocation.lng(),
        });
      }
    }

    return geocodedLocations;
  };

  const geocodeAddress = (geocoder, address) => {
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          resolve(results[0].geometry.location);
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
          resolve(null);
        }
      });
    });
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS}
      language="ru" // Set the language to Russian
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={selectedCityCoordinates ? { lat: selectedCityCoordinates.lat, lng: selectedCityCoordinates.lng } : defaultCenter}
        zoom={selectedCityCoordinates ? 12 : 10}
        onLoad={map => setMap(map)}
      >
        {locations.map((location, index) => (
          <Marker
            key={index}
            position={{ lat: location.lat, lng: location.lng }}
            onClick={() => setSelected(location)}
          />
        ))}

        {selected && (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => setSelected(null)}
          >
            <div>
              <LocationCard 
                id={selected.id}
                image={`${process.env.REACT_APP_BASE_URL}${selected.location_image.image}`}
                name={selected.name}
                address={selected.address}
                onCreateGame={() => console.log(`Create game at ${selected.name}`)}
                onViewLocation={() => console.log(`View location ${selected.name}`)}
              />
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
