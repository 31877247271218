import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import Cookies from 'js-cookie';


export const OrdinaryUserProfileCreation = () => {
  const [redirect, setRedirect] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    date_of_birth: '',
    user_location: '',
    profile_picture: null,
    description: '',
    preferred_categories: [],
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('content/v1/sport-categories/');
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error.response?.data || error.message);
      }
    };

    const fetchCities = async () => {
      try {
        const response = await axios.get('content/v1/cities/');
        setCities(response.data);
      } catch (error) {
        console.error('Failed to fetch cities:', error.response?.data || error.message);
      }
    };

    fetchCategories();
    fetchCities();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? [...formData.preferred_categories] : value;
    if (type === 'checkbox') {
      if (checked) {
        newValue.push(value);
      } else {
        newValue.splice(newValue.indexOf(value), 1);
      }
    }
    setFormData({ ...formData, [name]: newValue });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, profile_picture: e.target.files[0] });
  };

  const handleCityChange = (e) => {
    setFormData({ ...formData, user_location: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('date_of_birth', formData.date_of_birth);
    formDataToSend.append('user_location', formData.user_location);

    if (formData.profile_picture) {
      formDataToSend.append('profile_picture', formData.profile_picture);
    }

    formDataToSend.append('description', formData.description);
    formData.preferred_categories.forEach((category) => {
      formDataToSend.append('preferred_categories', category);
    });
    formDataToSend.append('user_id', Cookies.get('user_id'));

    setLoading(true);

    try {
      const response = await axios.post('user_actions/v1/ordinary-user-profiles/', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      setRedirect(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
          setError(error.response.data);
      } else {
        setError({ detail: "An unexpected error occurred. Please try again later." });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFormData({
      date_of_birth: '',
      user_location: '',
      profile_picture: null,
      description: '',
      preferred_categories: [],
    });
    // Reset file input if needed
    document.getElementById('profile_picture').value = '';
  };

  if (redirect) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50 pt-15">
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="text-white">Загрузка...</div>
        </div>
      )}
      <form onSubmit={handleSubmit} className="w-full max-w-4xl bg-white p-8 rounded-lg shadow-md">
        {error && <ErrorMessages errors={error} />}
        <div className="space-y-12">
          {/* Profile Section */}
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Создание профиля</h2>
            <p className="mt-1 text-sm leading-6 text-gray-900">
              Если вы пропустите шаг с созданием профиля, то не сможете зайти в систему под своей почтой!
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* Date of Birth */}
              <div className="sm:col-span-4">
                <label htmlFor="date_of_birth" className="block text-sm font-medium leading-6 text-gray-900">
                  Дата рождения
                </label>
                <div className="mt-2">
                  <input
                    id="date_of_birth"
                    name="date_of_birth"
                    type="date"
                    value={formData.date_of_birth}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

               {/* User Location */}
               <div className="sm:col-span-4">
                <label htmlFor="user_location" className="block text-sm font-medium leading-6 text-gray-900">
                  Ваша локация
                </label>
                <div className="mt-2">
                  <select
                    id="user_location"
                    name="user_location"
                    value={formData.user_location}
                    onChange={handleCityChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">Выберите город</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Profile Picture */}
              <div className="sm:col-span-full">
                <label htmlFor="profile_picture" className="block text-sm font-medium leading-6 text-gray-900">
                  Фото профиля (необязательно)
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                  {formData.profile_picture ? (
                    <img
                      src={URL.createObjectURL(formData.profile_picture)}
                      alt="Profile Preview"
                      className="h-12 w-12 rounded-full object-cover"
                    />
                  ) : (
                    <UserCircleIcon aria-hidden="true" className="h-12 w-12 text-gray-300" />
                  )}
                  <input
                    id="profile_picture"
                    name="profile_picture"
                    type="file"
                    onChange={handleFileChange}
                    className="text-sm text-gray-900"
                  />
                </div>
              </div>

              {/* Description */}
              <div className="sm:col-span-full">
                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                  Описание
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    rows={3}
                    value={formData.description}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Preferred Categories */}
              <div className="sm:col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">Любимые категории</label>
                <div className="mt-2 space-y-4">
                  {categories.map((category) => (
                    <div key={category.id} className="relative flex items-center gap-x-3">
                      <input
                        type="checkbox"
                        name="preferred_categories"
                        value={category.name}
                        checked={formData.preferred_categories.includes(category.name)}
                        onChange={handleChange}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label className="text-sm leading-6 text-gray-900">{category.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Buttons */}
          <div className="flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={handleReset}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Сбросить
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Создать Профиль
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};


const ErrorMessages = ({ errors }) => {
  // Ensure errors is always an object
  if (typeof errors !== 'object' || errors === null) {
      return null;
  }

  return (
      <div className="alert alert-danger" role="alert">
          {Object.keys(errors).map((key) => (
              <div key={key}>
                  {Array.isArray(errors[key])
                      ? errors[key].map((message, index) => (
                          <p key={index}>{message}</p>
                      ))
                      : <p>{errors[key]}</p>
                  }
              </div>
          ))}
      </div>
  );
};