import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LocationCard.css'; // Ensure you have your CSS file for styles

const LocationCard = ({ id, image, name, address, onCreateGame, onViewLocation }) => {
  const navigate = useNavigate();

  const handleViewLocation = () => {
    onViewLocation(); // This could also navigate to a details page or perform another action
    navigate(`/location/${id}`); // Assuming you have a route setup for viewing location details
  };

  const handleCreateGame = () => {
    onCreateGame(); // Trigger any game creation logic or navigate if needed
  };

  return (
    <div className="location-card">
      <img src={image} alt={name} className="location-image" onClick={handleViewLocation} />
      <div className="location-info">
        <h3>{name}</h3>
        <p>{address}</p>
        <div className="location-buttons">
          <button onClick={handleViewLocation}>Подробнее</button>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
