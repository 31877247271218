import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Modal from './Modal';
import CreateGameForm from './CreateGameForm';
import EventCard from './EventCard';
import EventDetail from './EventDetail'; // Import EventDetail component
import './LocationDetails.css';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../redux/authSlice';

const LocationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth.value);
  const { id } = useParams();
  const [location, setLocation] = useState(null);
  const [showCreateGameForm, setShowCreateGameForm] = useState(false);
  const [eventFilter, setEventFilter] = useState('future');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [registrationMessage, setRegistrationMessage] = useState(null);

 
  const baseURL=process.env.REACT_APP_BASE_URL;


  useEffect(() => {
    const checkAuth = async () => {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (accessToken) {
        dispatch(setAuth(true));
        setShowLoginPrompt(false);
      } else {
        dispatch(setAuth(false));
        setShowLoginPrompt(true);
      }
    };

    checkAuth();
  }, [dispatch, navigate]);

  useEffect(() => {
    // Fetch location data by ID
    const fetchLocation = async () => {
      try {
        const accessToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('access_token='))
          ?.split('=')[1];

        if (!accessToken) {
          setShowLoginPrompt(true);
          return;
        }

        const response = await axios.get(`content/v1/locations/${id}/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        setLocation(response.data);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchLocation();
  }, [id]);

  const handleCreateGame = () => {
    setShowCreateGameForm(true);
  };

  const handleCloseCreateGameForm = () => {
    setShowCreateGameForm(false);
  };

  const handleRegister = async () => {
    try {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) {
        alert("Please log in to register for the event.");
        return;
      }

      await axios.post(`user_actions/v1/events/${selectedEvent.id}/register/`, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      setRegistrationMessage(`Вы зарегистрировались на ${selectedEvent.name}`);
      setSelectedEvent(null);

      setTimeout(() => {
        setRegistrationMessage(null);
      }, 6000);
    } catch (error) {
      console.error("Error registering for event", error);
      setRegistrationMessage("Ошибка с регистрацией, возможно вы уже присоединились или игра закончена");
      setTimeout(() => {
        setRegistrationMessage(null);
      }, 3000);
    }
  };

  const filteredEvents = location && location.events ? location.events.filter(event => {
    return eventFilter === 'future' ? !event.is_over : event.is_over;
  }) : [];

  return (
    <div className="relative pt-2">
      {showLoginPrompt && (
        <>
          <div className="fixed inset-0 bg-gray-800 bg-opacity-70 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
            <div className="bg-white p-6 sm:p-8 md:p-10 lg:p-12 xl:p-16 rounded-lg shadow-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto relative">
              <h2 className="text-lg sm:text-xl font-semibold mb-4">Войдите или зарегистрируйтесь</h2>
              <p className="mb-4 text-sm sm:text-base">
                Для доступа к функциям сайта вам необходимо войти в систему или создать учетную запись с профилем
              </p>
              <p className="mb-4 text-sm sm:text-base">
                Если вы пропустите шаг с созданием профиля, то не сможете зайти в систему под своей почтой
              </p>
              <div className="flex flex-col sm:flex-row gap-2">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex-1"
                  onClick={() => navigate('/login')}
                >
                  Войти
                </button>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex-1"
                  onClick={() => navigate('/ordinary-user-signup')}
                >
                  Зарегистрироваться
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className={`location-detail ${showLoginPrompt ? 'blur-sm' : ''}`}>
        {location ? (
          <>
            <div className="location-info">
              <h2>{location.name}</h2>
              <div className="location-image">
                <img src={`${process.env.REACT_APP_BASE_URL}${location.location_image.image}`} alt={location.name} />
              </div>
              <div className="location-buttons">
                <button onClick={handleCreateGame}>Создать игру</button>
              </div>
              <p><strong>{location.address}, {location.city}</strong></p>
              <p>{location.description}</p>
            </div>

            <div className="events-section">
              <div className="events-tab-container">
                <div className="events-tab">
                  <button
                    className={`tab ${eventFilter === 'past' ? 'active' : ''}`}
                    onClick={() => setEventFilter('past')}
                  >
                    Прошедшие игры
                  </button>
                  <button
                    className={`tab ${eventFilter === 'future' ? 'active' : ''}`}
                    onClick={() => setEventFilter('future')}
                  >
                    Будущие игры
                  </button>
                </div>
              </div>

              <div className="events-content">
                {filteredEvents.length > 0 ? (
                  filteredEvents.map(event => (
                    <EventCard key={event.id} event={event} onClick={() => setSelectedEvent(event)} />
                  ))
                ) : (
                  <p>Нет игр</p>
                )}
              </div>
            </div>

            {showCreateGameForm && (
              <Modal isOpen={showCreateGameForm} onClose={handleCloseCreateGameForm}>
                <CreateGameForm onClose={handleCloseCreateGameForm} locationName={location.name} />
              </Modal>
            )}

            {selectedEvent && (
              <Modal isOpen={!!selectedEvent} onClose={() => setSelectedEvent(null)}>
                <EventDetail 
                  event={selectedEvent} 
                  onClose={() => setSelectedEvent(null)} 
                  showRegisterButton={true} 
                  handleRegister={handleRegister} 
                  registrationMessage={registrationMessage} 
                />
              </Modal>
            )}
          </>
        ) : (
          <p>Loading location details...</p>
        )}
      </div>
    </div>
  );
};

export default LocationDetails;
