import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import EventDetail from './EventDetail';
import './LocationDetails.css';


const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
};

const fetchProfileData = async (access_token, setProfile, setLoading, setError, dispatch, navigate) => {
    try {
        const response = await axios.get('user_actions/v1/profile/', {
            headers: { Authorization: `Bearer ${access_token}` }
        });
        dispatch(setAuth(true));
        setProfile(response.data);
    } catch (error) {
        setError('You are not authenticated');
        dispatch(setAuth(false));
    } finally {
        setLoading(false);
    }
};

const deleteProfile = async (access_token, setDeletionStatus, dispatch, navigate) => {
    try {
        await axios.delete('auth_service/v1/delete-user/', {
            headers: { Authorization: `Bearer ${access_token}` }
        });
        await axios.delete('auth_service/v1/token/delete-tokens-with-none-users/');
        setDeletionStatus('Profile deleted successfully.');
        dispatch(setAuth(false));
        deleteCookie('access_token');
        deleteCookie('refresh_token');
        deleteCookie('user_id');
        navigate('/');
    } catch (error) {
        setDeletionStatus('Failed to delete profile.');
    }
};


export const OrdinaryUserProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const auth = useSelector(state => state.auth.value);
    const [deletionStatus, setDeletionStatus] = useState('');
    const [eventFilter, setEventFilter] = useState('future');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const access_token = document.cookie
            .split('; ')
            .find(row => row.startsWith('access_token='))
            ?.split('=')[1];

        if (!access_token) {
            console.log('Access Token not found');
            dispatch(setAuth(false));
            navigate('/');
            return;
        }

        fetchProfileData(access_token, setProfile, setLoading, setError, dispatch, navigate);
    }, [dispatch, navigate]);

    const handleDeleteProfile = () => {
        const access_token = document.cookie
            .split('; ')
            .find(row => row.startsWith('access_token='))
            ?.split('=')[1];

        if (!access_token) {
            setDeletionStatus('Access token not found.');
            return;
        }

        deleteProfile(access_token, setDeletionStatus, dispatch, navigate);
    };

    const handleFilterChange = (filter) => {
        setEventFilter(filter);
    };

    const unregisterEvent = async (eventId) => {
        const access_token = document.cookie
            .split('; ')
            .find(row => row.startsWith('access_token='))
            ?.split('=')[1];

        if (!access_token) {
            setMessage('Access token not found.');
            return;
        }

        try {
            await axios.delete(`user_actions/v1/events/${eventId}/unregister/`, {
                headers: { Authorization: `Bearer ${access_token}` }
            });
            const response = await axios.get('user_actions/v1/profile/', {
                headers: { Authorization: `Bearer ${access_token}` }
            });
            setProfile(response.data);
            setMessage('Successfully unregistered from the event.');
        } catch (error) {
            setMessage('Failed to unregister from the event.');
        }
    };

    const handleEventClick = async (eventId) => {
        const access_token = document.cookie
          .split('; ')
          .find(row => row.startsWith('access_token='))
          ?.split('=')[1];
    
        if (!access_token) {
          setError('Access token not found.');
          return;
        }
    
        try {
          const response = await axios.get(`content/v1/user-event/${eventId}/`, {
            headers: { Authorization: `Bearer ${access_token}` }
          });
          setSelectedEvent(response.data);
        } catch (error) {
          setError('Failed to fetch event details.');
        }
      };
    
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (!auth) {
        navigate('/');
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const filteredEvents = profile?.registered_events?.filter(event => {
        const eventDate = new Date(event.date);
        const now = new Date();
        return eventFilter === 'future' ? !event.is_over : event.is_over;
    }) || [];

    return profile ? (
        <div className="flex flex-col items-center pt-10">
            <h3 className="text-lg font-semibold mb-2">{auth ? message : 'You are not authenticated'}</h3>
            <h1 className="text-2xl font-bold mb-4">{profile.username}</h1>
            <div className="flex flex-col items-center w-full max-w-md">
                <img
                    src={`${process.env.REACT_APP_BASE_URL}${profile.profile_picture}`}
                    alt="Profile"
                    className="w-32 h-32 object-cover rounded-full mb-4"
                />
                <div className="mt-6 border-t border-gray-200 w-full">
                    <dl className="divide-y divide-gray-200">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Почта</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.email || 'N/A'}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Дата рождения</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.date_of_birth || 'N/A'}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Локация</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.user_location || 'N/A'}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Описание</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.description || 'N/A'}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Любимые категории</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {profile.preferred_categories ? profile.preferred_categories.join(', ') : 'N/A'}
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Очки</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.points}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Уровень пользователя</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.user_level ? profile.user_level.name : 'N/A'}</dd>
                        </div>
                    </dl>
                </div>
    
                <h2 className="text-xl font-bold mt-6 mb-2">Мои игры</h2>
                <div className="events-tab-container mb-4">
                    <div className="events-tab">
                        <button
                            className={`tab ${eventFilter === 'past' ? 'active' : ''}`}
                            onClick={() => handleFilterChange('past')}
                        >
                            Прошедшие игры
                        </button>
                        <button
                            className={`tab ${eventFilter === 'future' ? 'active' : ''}`}
                            onClick={() => handleFilterChange('future')}
                        >
                            Будущие игры
                        </button>
                    </div>
                </div>

                <ul className="w-full max-w-md">
                    {filteredEvents.length > 0 ? (
                        filteredEvents.map(event => (
                            <li key={event.id} className="bg-white p-4 rounded-lg shadow mb-2 flex flex-col sm:flex-row sm:justify-between items-center">
                                <div className="flex flex-col sm:flex-row sm:items-center mb-2 sm:mb-0">
                                    <div className="sm:mr-4">
                                        <h3 className="text-lg font-semibold">{event.name}</h3>
                                        <p className="text-sm text-gray-500">{event.location.address}</p>
                                        <p className="text-sm text-gray-500">{new Date(event.date).toLocaleDateString()}</p>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={() => handleEventClick(event.id)}
                                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                                    >
                                        Подробнее
                                    </button>
                                    {!event.is_over && (
                                        <button
                                            onClick={() => unregisterEvent(event.id)}
                                            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                                        >
                                            Отписаться
                                        </button>
                                    )}
                                </div>
                            </li>
                        ))
                    ) : (
                        <li className="bg-white p-4 rounded-lg shadow mb-2">Нет игр</li>
                    )}
                </ul>

                <button
                    onClick={openModal}
                    className="mt-4 mb-10 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                >
                    Удалить аккаунт
                </button>
                {isModalOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                            <div className="mt-3 text-center">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Удаление аккаунта</h3>
                                <div className="mt-2 px-7 py-3">
                                    <p className="text-sm text-gray-500">Вы уверены, что хотите удалить свой профиль? Это действие невозможно отменить.</p>
                                </div>
                                <div className="items-center px-4 py-3">
                                    <button
                                        className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                                        onClick={handleDeleteProfile}
                                    >
                                        Да, удалить
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-gray-300 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 mt-3"
                                        onClick={closeModal}
                                    >
                                        Отмена
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {deletionStatus && (
                    <p className={`mt-4 ${deletionStatus.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>
                        {deletionStatus}
                    </p>
                )}
                {selectedEvent && (
                    <EventDetail
                        event={selectedEvent}
                        onClose={() => setSelectedEvent(null)}
                        showRegisterButton={false}
                    />
                )}
            </div>
        </div>
    ) : (
        <div>Loading...</div>
    );
}
