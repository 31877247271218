import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: false,
    userId: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            state.value = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
    },
});

export const {setAuth, setUserId} = authSlice.actions;

export default authSlice.reducer;