import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setAuth } from "../redux/authSlice";


export const User = () => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const auth = useSelector(state => state.auth.value);
    
    useEffect(() => {
        const fetchUserData = async () => {
            const access_token = document.cookie
                .split('; ')
                .find(row => row.startsWith('access_token='))
                ?.split('=')[1];

            if (!access_token) {
                console.log('Access Token not found');
                setMessage('You are not authenticated');
                return;
            }

            console.log('Access Token:', access_token);

            try {
                const {data} = await axios.get('auth_service/v1/user/', {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
                setMessage(`Email: ${data.email}\nЛогин${data.username}\nРейтинг: ${data.rating}`);
                dispatch(setAuth(true));
            } catch (error) {
                setMessage('You are not authenticated');
                dispatch(setAuth(false));
            }
        };

        fetchUserData();
    }, []);

    return (
        <div className="container mt-5 text-center">
            <h3>{auth ? message : 'You are not authenticated'}</h3>
        </div>
    );
};