import React, { useState } from "react";
import axios from "axios";
import { Navigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserId } from "../redux/authSlice";
import Cookies from 'js-cookie'; 
import '../styles/OrdinaryUserSignup.css';

export const OrdinaryUserSignup = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('auth_service/v1/ordinary-user-signup/', {
                email,
                username,
                role: "Ordinary user",
                password,
                password_confirm: passwordConfirm
            });
            dispatch(setUserId(response.data.id));
            Cookies.set('user_id', response.data.id);
            setRedirect(true);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError(error.response.data);
            } else {
                setError({ detail: "An unexpected error occurred. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    }

    if (redirect) {
        return <Navigate to={"/create-ordinary-user-profile"} />;
    }

    return (
      <div>
          {loading && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
                  <div className="text-white">Загрузка...</div>
              </div>
          )}
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                  <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                      Регистрация
                  </h2>
              </div>

              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  <form onSubmit={submit} className="space-y-6">
                      {error && <ErrorMessages errors={error} />}
                      <div>
                          <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                          >
                              Адрес почты
                          </label>
                          <div className="mt-2">
                              <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  required
                                  autoComplete="email"
                                  placeholder="name@example.com"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => setEmail(e.target.value)}
                              />
                          </div>
                      </div>

                      <div>
                          <label
                              htmlFor="username"
                              className="block text-sm font-medium leading-6 text-gray-900"
                          >
                              Логин
                          </label>
                          <div className="mt-2">
                              <input
                                  id="username"
                                  name="username"
                                  type="text"
                                  required
                                  autoComplete="username"
                                  placeholder="username"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => setUsername(e.target.value)}
                              />
                          </div>
                      </div>

                      <div>
                          <div className="flex items-center justify-between">
                              <label
                                  htmlFor="password"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                  Пароль
                              </label>
                          </div>
                          <div className="mt-2">
                              <input
                                  id="password"
                                  name="password"
                                  type="password"
                                  required
                                  autoComplete="current-password"
                                  placeholder="Не меньше 8 символов"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => setPassword(e.target.value)}
                              />
                          </div>
                      </div>

                      <div>
                          <div className="flex items-center justify-between">
                              <label
                                  htmlFor="passwordConfirm"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                  Подтвердить пароль
                              </label>
                          </div>
                          <div className="mt-2">
                              <input
                                  id="passwordConfirm"
                                  name="passwordConfirm"
                                  type="password"
                                  required
                                  autoComplete="current-password"
                                  placeholder="Пароль должен совпадать с предыдущим"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) => setPasswordConfirm(e.target.value)}
                              />
                          </div>
                      </div>

                      <div>
                          <button
                              type="submit"
                              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                              Зарегистрироваться
                          </button>
                      </div>
                  </form>

                  <p className="mt-10 text-center text-sm text-gray-500">
                      Уже есть профиль?{" "}
                      <a
                          href="/login"
                          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                      >
                          Войти
                      </a>
                  </p>
              </div>
          </div>
      </div>
  );
}

const ErrorMessages = ({ errors }) => {
    // Ensure errors is always an object
    if (typeof errors !== 'object' || errors === null) {
        return null;
    }

    return (
        <div className="alert alert-danger" role="alert">
            {Object.keys(errors).map((key) => (
                <div key={key}>
                    {Array.isArray(errors[key])
                        ? errors[key].map((message, index) => (
                            <p key={index}>{message}</p>
                        ))
                        : <p>{errors[key]}</p>
                    }
                </div>
            ))}
        </div>
    );
};
