import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;

axios.defaults.withCredentials = true;
let refresh = false;

// Function to get a specific cookie's value
const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

// Function to delete a specific cookie
const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
};

// Function to verify refresh token
const verifyRefreshToken = async (token) => {
    try {
        await axios.post("auth_service/v1/token/verify/", { refresh_token: token });
        return true;
    } catch (error) {
        return false;
    }
};

export default getCookie;

axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !refresh) {
            refresh = true;

            // Get the refresh token from cookies
            const refresh_token = getCookie('refresh_token');

            // Verify the refresh token
            const isTokenValid = await verifyRefreshToken(refresh_token);

            if (isTokenValid) {
                try {
                    // Attempt to refresh the access token
                    const response = await axios.post("auth_service/v1/token/refresh/", {
                        refresh: refresh_token
                    });

                    if (response.status === 200) {
                        const new_access_token = response.data.access;

                        // Update the access token in cookies
                        document.cookie = `access_token=${new_access_token}; path=/`;

                        // Retry the original request with the new access token
                        originalRequest.headers['Authorization'] = `Bearer ${new_access_token}`;

                        return axios(originalRequest);
                    }
                } catch (refreshError) {
                    // Clear cookies and redirect to login if refresh token refresh fails
                    deleteCookie('access_token');
                    deleteCookie('refresh_token');
                    deleteCookie('user_id');
                    refresh = false;
                    return Promise.reject(refreshError);
                }
            } else {
                // Clear cookies and redirect to login if refresh token is invalid
                deleteCookie('access_token');
                deleteCookie('refresh_token');
                deleteCookie('user_id');
                refresh = false;
                return Promise.reject(new Error("Refresh token is invalid"));
            }
        }
        refresh = false;
        return Promise.reject(error); // Ensure the original error is propagated
    }
);