import React, { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { setAuth } from "../redux/authSlice";

export const Login = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('auth_service/v1/login/', {
                email: email,
                password: password
            });

            // Extract the access token from the response
            const access_token = response.data.access_token;

            // Extract the refresh token from the response
            const refresh_token = response.data.refresh_token;

            // Set the access token to cookies
            document.cookie = `access_token=${access_token}; path=/`;

            // Set the refresh token to cookies
            document.cookie = `refresh_token=${refresh_token}; path=/`;

            dispatch(setAuth(true));
            setRedirect(true);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError('Ошибка при входе');
                } else {
                    setError('Ошибка при входе');
                }
            } else {
                setError('Ошибка при входе');
            }
        } finally {
          setLoading(false);
        }
    }

    if (redirect) {
        return <Navigate to={"/profile"} />;
    }

    return (
      <div>
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="text-white">Загрузка...</div>
          </div>
        )}
    
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Войти
            </h2>
          </div>
    
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={submit} className="space-y-6">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
    
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Адрес почты
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    placeholder="name@example.com"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
    
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Пароль
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
    
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Войти
                </button>
              </div>
            </form>
    
            <p className="mt-10 text-center text-sm text-gray-500">
              Еще нет профиля?{" "}
              <a
                href="/ordinary-user-signup"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Зарегистрироваться
              </a>
            </p>
          </div>
        </div>
      </div>
    );
}    