import React from 'react';
import './Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer bg-white border-t-2 border-gray-200 py-2">
      <div className="flex items-center justify-between px-4 lg:px-6">
        <div className="flex lg:flex-1 lg:justify-start">
          <a href="/" className="-m-1 p-1">
            <span className="sr-only">Pocket Sport</span>
            <img
              alt="Pocket Sport Logo"
              src="/pocket-sport-logo.png"
              className="h-16 w-auto"
            />
          </a>
        </div>
        <div className="flex lg:flex-1 lg:justify-center">
          <p className="text-xs font-semibold leading-5 text-gray-900">
            Объединяем спортсменов с 2024!
          </p>
        </div>
        <div className="flex lg:flex-1 lg:justify-end">
          <ul className="flex items-center gap-x-4">
            <li>
              <a href="https://forms.gle/McBd9ERQ3rchC7SM9" className="text-xs font-semibold leading-5 text-gray-900 no-underline border-b-2 border-transparent hover:border-gray-500">
                Обратная связь
              </a>
            </li>
            <li>
              <a href="https://vk.com/pocketssports" target="_blank" rel="noopener noreferrer">
                <img src="/VK_Logo.png" alt="VK" className="vk-logo h-6 w-auto" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
