import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import getCookie from '../interceptors/axios';
import './AddCourt.css'; // Ensure this is the correct path

const AddCourt = () => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const [cities, setCities] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('content/v1/cities/');
        setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const handleFormSubmit = async (values, acceptedFiles, setSubmitting) => {
    const token = getCookie('access_token');
    console.log(token); // Fetch the token dynamically

    if (!token) {
      setError('Войдите или зарегестрируйтесь');
      setSubmitting(false); // Reset the submitting state
      return;
    }

    const formData = new FormData();
    formData.append('address', values.address);
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('city', values.city);

    acceptedFiles.forEach((file, index) => {
      formData.append(`uploaded_location_submission_image`, file);
    });

    try {
      const response = await axios.post('user_actions/v1/submit-location/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Court added successfully:', response.data);

      if (response.status === 201) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 1200);
      }

      setError(''); // Clear any previous errors
    } catch (error) {
      if (error.response) {
        const errorResponse = error.response.data;
        let errorMessage = 'An unexpected error occurred. Please try again later.';

        if (errorResponse.name && errorResponse.name[0] === "location submission with this name already exists.") {
          errorMessage = 'Локация с таким названием уже существует';
        } else if (errorResponse.uploaded_location_submission_image) {
          errorMessage = errorResponse.uploaded_location_submission_image.join(' ');
        }

        setError(errorMessage);
      } else {
        setError('Network error. Please check your internet connection.');
      }
      console.error('Error adding court:', error);
    } finally {
      setSubmitting(false); 
    }
  };

  return (
    <div className="add-court-container">
      <h1 className="title">Добавьте площадку!</h1>
      {success && (
        <div className="alert-success" role="alert">
          Площадка успешно добавлена!
        </div>
      )}
      {!success && (
        <Formik
          initialValues={{ address: '', name: '', description: '', city: '' }}
          validationSchema={Yup.object({
            address: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values, acceptedFiles, setSubmitting);
          }}
        >
          <Form className="form">
            {error && (
              <div className="alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="form-group">
              <label htmlFor="city" className="label">Город</label>
              <Field as="select" name="city" className="input-field">
                <option value="">Выберите ваш город</option>
                {cities.map((city) => (
                  <option key={city.id} value={city.name}>{city.name}</option>
                ))}
              </Field>
              <ErrorMessage name="city" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="address" className="label">Адрес</label>
              <Field name="address" type="text" className="input-field" />
              <ErrorMessage name="address" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="name" className="label">Назовите площадку</label>
              <Field name="name" type="text" className="input-field" />
              <ErrorMessage name="name" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="description" className="label">Краткое описание(как пройти)</label>
              <Field name="description" as="textarea" className="textarea-field" />
              <ErrorMessage name="description" component="div" className="error-message" />
            </div>

            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <p>Drag & drop, загрузите фото площадки</p>
            </div>
            <div>
              <h4>Принятые файлы:</h4>
              <ul>
                {acceptedFiles.map(file => (
                  <li key={file.path}>{file.path}</li>
                ))}
              </ul>
            </div>

            <div className="buttons">
              <button type="reset" className="reset-button">Сбросить</button>
              <button type="submit" className="submit-button">Добавить</button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default AddCourt;
