import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EventDetail.css';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import axios from 'axios';

// Helper function to format date and time
const formatDate = (dateString) => {
  const [datePart, timePart] = dateString.split(' ');
  const [day, month, year] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  // Create a Date object
  const date = new Date(year, month - 1, day, hours, minutes);

  // Format date and time
  const formattedDate = date.toLocaleDateString('ru-RU', { year: 'numeric', month: 'long', day: 'numeric' });
  const formattedTime = date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });

  return { formattedDate, formattedTime };
};

const EventDetail = ({ event, onClose, handleRegister, registrationMessage, showRegisterButton }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.value);
  const [participants, setParticipants] = useState([]);
  const [loadingParticipants, setLoadingParticipants] = useState(true);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (accessToken) {
        dispatch(setAuth(true));
        setShowLoginPrompt(false);
      } else {
        dispatch(setAuth(false));
        setShowLoginPrompt(true);
      }
    };

    checkAuth();
  }, [dispatch, navigate]);
  
  
  useEffect(() => {
    if (event) {
      const fetchParticipants = async () => {
        try {
          const accessToken = document.cookie
            .split('; ')
            .find(row => row.startsWith('access_token='))
            ?.split('=')[1];

          if (!accessToken) {
            setShowLoginPrompt(true);
            return;
          }

          const response = await axios.get(`user_actions/v1/events/${event.id}/participants/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          setParticipants(response.data);  // Axios automatically parses JSON
        } catch (error) {
          console.error('Error fetching participants:', error);
        } finally {
          setLoadingParticipants(false);
        }
      };

      fetchParticipants();
    }
  }, [event]);

  // Return loading state if event is not yet available
  if (!event) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg sm:max-w-md md:max-w-xl lg:max-w-2xl relative">
          <p className="text-center">Загрузка...</p>
        </div>
      </div>
    );
  }

  // Extract and format date and time
  const { formattedDate, formattedTime } = formatDate(event.event_date);

  // Handle location click
  const handleLocationClick = () => {
    navigate(`/location/${event.location_id}`); // Navigate to location details page
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg sm:max-w-md md:max-w-xl lg:max-w-2xl relative max-h-[calc(100vh-4rem)] overflow-y-auto flex flex-col items-center">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-xl"
        >
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4 text-center">{event.name}</h2>
        <div className="mb-4 w-full flex justify-center">
          <img
            src={event.location_image_url ? `${process.env.REACT_APP_BASE_URL}${event.location_image_url}` : '/default-image.png'}
            alt={event.name}
            className="w-full h-auto max-h-60 object-cover rounded"
          />
        </div>
        <div className="space-y-4 w-full text-lg font-medium">
          <p className="border-b pb-2 text-center">
            <a
              onClick={handleLocationClick}
              className="text-blue-500 hover:underline cursor-pointer"
            >
              Площадка: {event.location_name}
            </a>
          </p>
          <p className="border-b pb-2 text-center">Категория: {event.category}</p>
          <p className="border-b pb-2 text-center">Адрес: {event.event_address}, {event.event_city}</p>
          <p className="border-b pb-2 text-center">Дата: {formattedDate}</p>
          <p className="border-b pb-2 text-center">Время: {formattedTime}</p>
          <p className="border-b pb-2 text-center">Продолжительность: {event.duration}</p>
          <p className="border-b pb-2 text-center">Описание: {event.description}</p>
          <p className="border-b pb-2 text-center">
            <div className="mt-4">
              <h3 className="text-xl font-semibold text-center mb-4">Участники: {event.current_participant_number}/{event.capacity}</h3>
              {loadingParticipants ? (
                <p className="text-center">Загрузка участников...</p>
              ) : (
                <div className="grid grid-cols-2 gap-4 transition-all duration-300">
                  {participants.map((participant, index) => (
                    <div
                      key={index}
                      className="p-2 rounded-md hover:bg-gray-100 transition-colors duration-300 ease-in-out text-center"
                    >
                      <img
                        src={participant.user_profile.profile_picture ? `${process.env.REACT_APP_BASE_URL}${participant.user_profile.profile_picture}` : '/default-avatar.png'}
                        alt={participant.user_profile.username}
                        className="w-12 h-12 mx-auto rounded-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                      />
                      <span className="font-medium text-gray-700 text-lg block mt-2">
                        {participant.user_profile.username}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </p>

          {showRegisterButton && (
            <div className="text-center mt-4">
              <button onClick={handleRegister} className="register-btn bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Зарегистрироваться</button>
              {registrationMessage && <p className="mt-2 text-green-500">{registrationMessage}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
);
};

export default EventDetail;